:root {
  --grey1: rgb(242, 242, 242);
  --grey2: rgb(208, 208, 208);
  --grey3: rgb(170, 170, 170);
  --blue1: rgb(4, 2, 96);
  --blue2: rgb(42, 0, 255);
}

@font-face {
  font-family: "Ford Antenna";
  src: url("fonts/FordAntenna-Medium.eot");
  src: local("Ford Antenna Medium"), local("FordAntenna-Medium"),
    url("fonts/FordAntenna-Medium.eot?#iefix") format("embedded-opentype"),
    url("fonts/FordAntenna-Medium.woff") format("woff"),
    url("fonts/FordAntenna-Medium.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Ford Antenna";
  src: url("fonts/FordAntenna-MediumItalic.eot");
  src: local("Ford Antenna Medium Italic"), local("FordAntenna-MediumItalic"),
    url("fonts/FordAntenna-MediumItalic.eot?#iefix") format("embedded-opentype"),
    url("fonts/FordAntenna-MediumItalic.woff") format("woff"),
    url("fonts/FordAntenna-MediumItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "AntennaRegular";
  src: local("AntennaRegular"), url("fonts/FordAntenna-Light.eot") format("eot"),
    url("fonts/FordAntenna-Light.ttf") format("ttf"),
    url("fonts/FordAntenna-Light.woff") format("woff");
  font-weight: 400;
}

* {
  font-family: "Ford Antenna", sans-serif !important;
}
